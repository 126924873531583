//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mixin, mixinDevice } from '@/utils/mixin';
import {
  StatusRenderer,
  ActionsCellRenderer,
  ContentRenderer,
  DateRenderer,
  SchedulingRenderer,
  TargetRenderer,
  LanguagesRenderer,
  AttachmentRenderer,
  IDRenderer,
  LikeRenderer
} from './grid';

import { mapGetters } from 'vuex';
import { MESSAGE_STATUS } from '@/enum';
import moment from 'moment';
import PageHeader from '@/components/tools/PageHeaderCustom.vue';
import SearchBox from './search-box/SearchBox.vue';

export default {
  components: {
    PageHeader,
    SearchBox,
    StatusRenderer,
    ActionsCellRenderer,
    ContentRenderer,
    DateRenderer,
    SchedulingRenderer,
    TargetRenderer,
    LanguagesRenderer,
    AttachmentRenderer,
    IDRenderer,
    LikeRenderer
  },
  mixins: [mixin, mixinDevice],

  data() {
    return {
      context: { componentParent: this },
      messageStatus: MESSAGE_STATUS,

      loading: true,
      labelCol: { lg: { span: 8 }, md: { span: 24 } },
      wrapperCol: { lg: { span: 16 }, md: { span: 24 } },

      // Table settings
      columnDefs: [
        {
          headerName: 'message.grid.ID',
          width: 80,
          field: 'id',
          sortable: true,
          unSortIcon: true,
          lockPosition: true,
          cellClass: 'locked-col',
          cellRenderer: 'IDRenderer'
        },
        {
          headerName: 'message.grid.Content',
          width: 400,
          field: 'default_title',
          sortable: true,
          unSortIcon: true,
          suppressSizeToFit: true,
          lockPosition: true,
          cellClass: 'locked-col',
          cellRenderer: 'ContentRenderer'
        },
        {
          headerName: 'message.grid.Languages',
          width: 80,
          field: 'languages',
          lockPosition: true,
          cellClass: 'locked-col',
          cellRenderer: 'LanguagesRenderer'
        },
        {
          headerName: 'message.grid.Attachments',
          width: 120,
          field: 'attachments',
          lockPosition: true,
          cellClass: 'locked-col',
          cellRenderer: 'AttachmentRenderer'
        },
        {
          headerName: 'message.grid.Status',
          field: 'status',
          width: 80,
          sortable: true,
          unSortIcon: true,
          lockPosition: true,
          cellClass: 'locked-col',
          cellRenderer: 'StatusRenderer'
        },
        {
          headerName: 'message.grid.Scheduling',
          width: 180,
          field: 'scheduling',
          lockPosition: true,
          cellClass: 'locked-col',
          cellRenderer: 'SchedulingRenderer'
        },
        {
          headerName: 'message.grid.Target',
          width: 180,
          field: 'target',
          lockPosition: true,
          cellClass: 'locked-col',
          cellRenderer: 'TargetRenderer'
        },
        {
          headerName: 'message.grid.Like',
          width: 60,
          field: 'like',
          lockPosition: true,
          cellClass: 'locked-col',
          cellRenderer: 'LikeRenderer'
        },
        {
          headerName: 'message.grid.Created',
          field: 'created',
          width: 130,
          sortable: true,
          unSortIcon: true,
          lockPosition: true,
          cellClass: 'locked-col',
          cellRenderer: 'DateRenderer'
        },
        {
          headerName: 'message.grid.Updated',
          field: 'modified',
          width: 130,
          lockPosition: true,
          cellClass: 'locked-col',
          cellRenderer: 'DateRenderer'
        },
        {
          resizable: false,
          headerName: 'message.grid.Actions',
          width: 50,
          pinned: 'right',
          field: 'actions',
          lockPosition: true,
          cellClass: 'locked-col',
          cellRenderer: 'ActionsCellRenderer'
        }
      ],

      // search
      queryParam: {
        status: 'ALL',
        title: ''
      },

      pagination: {},

      // datascreen
      rowData: [],
      selectedRows: 0,
      form: this.$form.createForm(this, { name: 'company_search' }),
      messageCount: 0
    };
  },
  computed: {
    ...mapGetters('user', ['userInfo']),
    gridApi() {
      return this.$refs.sGrid.gridApi;
    },
    columnApi() {
      return this.$refs.sGrid.columnApi;
    },
    filter() {
      const { title, status, order } = this.queryParam;
      let where = {
        ...(title && { all_title: { ilike: `%${title}%` } }),
        ...(status && status !== 'ALL' && { status: { eq: status } })
      };

      const skip = (this.pagination.current - 1) * this.pagination.pageSize;
      const filter = {
        limit: this.pagination.pageSize,
        skip,
        where,
        order: order || 'created DESC'
        // include: ['code']
      };

      return filter;
    },
    isActiveClear() {
      const { title, status } = this.$route.query;
      return (status && status !== 'ALL') || title;
    },
    countPushMax() {
      return this.userInfo?.company?.pricing_data?.push_subscription?.count;
    },
    countPushRemain() {
      return this.countPushMax - this.messageCount;
    }
  },
  created() {
    const { title, status, order } = this.$route.query;
    this.queryParam = {
      title: title || '',
      status: status || 'ALL',
      ...(order && { order })
    };

    this.pagination.pageSize = +this.$route.query.size || 20;
    this.pagination.current = +this.$route.query.page || 1;

    this.getData();
  },
  methods: {
    handleReset() {
      this.queryParam = {
        status: 'ALL'
      };
      this.handleSearch();
    },
    async getData() {
      this.loading = true;
      try {
        const companyId = this.userInfo.company_id;
        const [messages, count, messageCount] = await Promise.all([
          this.$s.api.message.find(companyId, { filter: this.filter }),
          this.$s.api.message.count(companyId, this.filter.where),
          this.$s.api.message.count(this.userInfo.company_id, {
            created: { gt: moment().format('YYYY-MM-01') }
          })
        ]);
        this.pagination.total = count.count;
        this.rowData = [...messages];
        this.messageCount = messageCount.count;
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    changeTitle(value) {
      this.queryParam.title = value;
    },
    handleSearch() {
      // e.preventDefault();
      this.$router.replace({ query: this.queryParam });
    },
    changeStatus(value) {
      this.queryParam.status = value;
      this.handleSearch();
    },

    onSelectionChanged() {
      const rows = this.gridApi.getSelectedRows();
      this.selectedRows = rows.length;
    },

    onPageChange({ page, pageSize }) {
      const query = {
        ...this.$route.query,
        page,
        size: pageSize
      };
      this.$router.replace({ query });
    },
    doAdd() {
      this.$router.push({ path: '/message/compose' });
    },
    async updateStatus(companyId, messageId, status) {
      try {
        this.loading = true;
        await this.$s.api.message.updateStatus(companyId, messageId, status);
        const mappingDescription = {
          DRAFT: 'This message is rollbacked',
          CANCEL: 'This message has been cancelled'
        };
        this.$notification.success({ message: this.$t(mappingDescription[status]) });
        this.queryParam = { ...this.queryParam, status };
        this.handleSearch();
      } catch (error) {
        console.log(error);
        this.$notification.error({
          description: this.$t('Updated message is fail')
        });
      } finally {
        this.loading = false;
      }
    },
    async deleteMessage(companyId, messageId) {
      try {
        this.loading = true;
        await this.$s.api.message.delete(companyId, messageId);
        this.$notification.success({
          message: this.$t('Success'),
          description: this.$t('This message is deleted')
        });
        this.getData();
      } catch (error) {
        console.log(error);
        this.$notification.error({
          message: this.$t('Error'),
          description: this.$t('Deleted message is fail')
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
