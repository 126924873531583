var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("span", { staticClass: "mr-xs" }, [_vm._v(_vm._s(_vm.likeCount))]),
      _c("a-icon", { staticStyle: { color: "red" }, attrs: { type: "heart" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }