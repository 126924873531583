//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import { mapGetters } from 'vuex';
import { APP_TARGET } from '@/enum/index';
export default Vue.extend({
  computed: {
    ...mapGetters('resource', ['prefData', 'newCityCodeToName']),
    info() {
      return this.params.data || {};
    },
    targetMsg() {
      let targets = {};
      // if (this.info.filter_gender) {
      //   targets.Gender = this.info.filter_gender;
      // }
      // if (this.info.filter_min_age && this.info.filter_min_age > -1) {
      //   targets.Age = `${this.info.filter_min_age} ~ ${this.info.filter_max_age}`;
      // }
      if (this.info.app_target) {
        targets.AppTarget = this.info.app_target.map(item => APP_TARGET[item]).join(', ');
      }
      return targets;
    },
    locationKeys() {
      let locationKeys = [];
      if (this.info.filter_prefecture) {
        locationKeys = [...locationKeys, ...this.info.filter_prefecture];
      }
      if (this.info.filter_city) {
        locationKeys = [...locationKeys, ...this.info.filter_city];
      }
      if (this.info.filter_ward) {
        locationKeys = [...locationKeys, ...this.info.filter_ward];
      }
      return locationKeys;
    }
  }
});
