//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable no-undef */
import Vue from 'vue';
import { mapGetters } from 'vuex';
import Preview from '../preview/Index.vue';
export default Vue.extend({
  components: {
    Preview
  },
  computed: {
    ...mapGetters('user', ['userInfo']),
    info() {
      return this.params.data;
    },
    haveStatistic() {
      const status = this.params.data['status'];
      return status === 'SENDING' || status === 'DONE' || status === 'CANCEL';
    },
    companyId() {
      return this.userInfo.company_id;
    },
    messageId() {
      return this.params.data.id;
    },
    status() {
      return this.params.data.status;
    }
  },
  methods: {
    updateStatusCancel() {
      this.updateStatus('CANCEL');
    },
    updateStatusDraft() {
      this.updateStatus('DRAFT');
    },
    updateStatus(status) {
      this.params.context.componentParent.updateStatus(this.companyId, this.messageId, status);
    },
    deleteMessage() {
      this.params.context.componentParent.deleteMessage(this.companyId, this.messageId);
    },
    preview() {
      const contentData = {
        attachment: this.info.attachment,
        localized_messages: this.info.localized_messages[0],
        qrcode: this.info.qrcode
      };
      this.$refs.previewRef.showPreview(contentData);
    }
  }
});
