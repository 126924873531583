//
//
//

import Vue from 'vue';
export default Vue.extend({
  computed: {
    id() {
      return this.params.data.id;
    }
  }
});
