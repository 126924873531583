var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex", "flex-direction": "row" } },
    [
      _vm._l(_vm.attachments, function(attachment) {
        return _c(
          "div",
          { key: attachment },
          [
            _c(
              "a-tooltip",
              { attrs: { placement: "top", title: _vm.$t(attachment) } },
              [
                _c("a-icon", {
                  staticClass: "icon-attachment",
                  style: { color: _vm.iconColor[attachment] },
                  attrs: { type: _vm.iconType[attachment] }
                })
              ],
              1
            )
          ],
          1
        )
      }),
      _vm.qrcode
        ? _c(
            "div",
            [
              _c(
                "a-tooltip",
                { attrs: { placement: "top", title: _vm.$t("Voice code") } },
                [
                  _c("img", {
                    staticStyle: {
                      width: "20px",
                      height: "20px",
                      "margin-bottom": "8px"
                    },
                    attrs: { src: require("@/assets/logo.png") }
                  })
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }