//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
export default Vue.extend({
  computed: {
    info() {
      return this.params.data;
    },
    isRejected() {
      return this.params.data['status'] === 'REJECTED';
    },
    isWaiting() {
      return this.params.data['status'] === 'WAITING';
    },
    isDraft() {
      return this.params.data['status'] === 'DRAFT';
    },
    isSent() {
      return this.params.data['status'] === 'SENDING';
    },
    isDone() {
      return this.params.data['status'] === 'DONE';
    },
    scheduleTotal() {
      return this.info?.scheduled_total || 0;
    },
    scheduledActivated() {
      return this.info?.scheduler_activated || 0;
    }
  }
});
