//
//
//
//
//
//
//

import Vue from 'vue';
export default Vue.extend({
  computed: {
    info() {
      return this.params.data;
    },
    isRejected() {
      return this.params.data['status'] === 'REJECTED';
    }
  }
});
