//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { MESSAGE_STATUS } from '@/enum';

export default {
  props: {
    queryParam: { type: Object, require: true },
  },
  data() {
    return {
      messageStatus: MESSAGE_STATUS,
      statusTimer: null
    };
  },
  computed: {
    acitveClearButton() {
      const { title, status } = this.queryParam;
      return title !== '' || status !== 'ALL';
    },
    statusText() {
      const { status } = this.queryParam;
      const statusItem = this.messageStatus[status];
      return statusItem ? this.$t(statusItem) : this.$t('All');
    },
  },

  methods: {
    changeTitle(value) {
      this.$emit('changeTitle', value);
      this.handleSearch();
    },
    changeStatus(event) {
      this.$emit('changeStatus', event.key);
    },
    handleReset() {
      this.$emit('handleReset');
    },
    handleSearch() {
      this.$emit('handleSearch');
    }
  }
};
