//
//
//
//
//
//
//
//

import Vue from 'vue';
export default Vue.extend({
  computed: {
    languages() {
      return this.params.data.localized_messages.map(item => this.$t(item.lang));
    }
  }
});
