var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cell-wrapper-grid-content" }, [
    Object.keys(_vm.targetMsg).length > 0 || _vm.locationKeys.length > 0
      ? _c(
          "div",
          [
            _vm._l(Object.keys(_vm.targetMsg), function(msg) {
              return _c(
                "p",
                { key: msg, staticStyle: { padding: "0px", margin: "0px" } },
                [
                  _c("span", { staticClass: "mr-xs" }, [
                    _vm._v(_vm._s(_vm.$t(msg)) + ":")
                  ]),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm._f("truncate")(_vm.$t(_vm.targetMsg[msg]), 30))
                    )
                  ])
                ]
              )
            }),
            _vm.locationKeys.length > 0
              ? _c(
                  "div",
                  [
                    _c("span", { staticClass: "mr-xs" }, [
                      _vm._v(_vm._s(_vm.$t("Location")) + ":")
                    ]),
                    _vm.locationKeys.length > 3
                      ? _c(
                          "a-tooltip",
                          [
                            _c(
                              "template",
                              { slot: "title" },
                              _vm._l(_vm.locationKeys, function(item) {
                                return _c("a-tag", { key: item }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.newCityCodeToName(item)) +
                                      " "
                                  )
                                ])
                              }),
                              1
                            ),
                            _vm._l(_vm.locationKeys.slice(0, 3), function(
                              item
                            ) {
                              return _c("a-tag", { key: item }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.newCityCodeToName(item)) +
                                    " "
                                )
                              ])
                            }),
                            _c("span", [_vm._v("...")])
                          ],
                          2
                        )
                      : _c(
                          "span",
                          _vm._l(_vm.locationKeys, function(item) {
                            return _c("a-tag", { key: item }, [
                              _vm._v(_vm._s(_vm.newCityCodeToName(item)))
                            ])
                          }),
                          1
                        )
                  ],
                  1
                )
              : _vm._e()
          ],
          2
        )
      : _c("div", [_vm._v(_vm._s(this.$t("All users")))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }