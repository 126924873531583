//
//
//
//
//
//

import Vue from 'vue';
export default Vue.extend({
  computed: {
    likeCount() {
      return this.params.data.liked_count;
    }
  }
});
