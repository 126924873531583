var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-dropdown",
        { attrs: { trigger: ["click"], placement: "bottomRight" } },
        [
          _c("q-btn", { attrs: { flat: "", dense: "", icon: "more_vert" } }),
          _c(
            "a-menu",
            {
              staticStyle: { width: "180px" },
              attrs: { slot: "overlay" },
              slot: "overlay"
            },
            [
              _c("a-menu-item", { key: "0", on: { click: _vm.preview } }, [
                _vm._v(" " + _vm._s(_vm.$t("Preview")) + " ")
              ]),
              _c(
                "a-menu-item",
                { key: "1" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "message-detail",
                          params: { id: _vm.info.id }
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.status !== "DRAFT"
                              ? _vm.$t("ViewCell")
                              : _vm.$t("Edit")
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm.status === "SENDING"
                ? _c(
                    "a-menu-item",
                    { key: "2" },
                    [
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            placement: "left",
                            title: _vm.$t(
                              "Are you sure to cancel publishing this message?"
                            ),
                            "ok-text": _vm.$t("Yes"),
                            "cancel-text": _vm.$t("No")
                          },
                          on: { confirm: _vm.updateStatusCancel }
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.$t("Cancel")))])]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              ["OWNER_WAITING", "WAITING", "APPROVED"].includes(_vm.status)
                ? _c(
                    "a-menu-item",
                    { key: "2" },
                    [
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            placement: "left",
                            title: _vm.$t(
                              "Are you sure to return this message to draft?"
                            ),
                            "ok-text": _vm.$t("Yes"),
                            "cancel-text": _vm.$t("No")
                          },
                          on: { confirm: _vm.updateStatusDraft }
                        },
                        [
                          _c("span", { staticStyle: { display: "block" } }, [
                            _vm._v(_vm._s(_vm.$t("Roll Back")))
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-menu-item",
                { key: "3", attrs: { disabled: _vm.status !== "DRAFT" } },
                [
                  _c(
                    "a-popconfirm",
                    {
                      attrs: {
                        placement: "left",
                        title: _vm.$t("Are you sure delete this message"),
                        "ok-text": _vm.$t("Yes"),
                        "cancel-text": _vm.$t("No"),
                        disabled: _vm.status !== "DRAFT"
                      },
                      on: { confirm: _vm.deleteMessage }
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v(_vm._s(_vm.$t("Delete")))
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("preview", { ref: "previewRef" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }