var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cell-wrapper-grid-content" },
    [
      _c(
        "a-tooltip",
        { attrs: { placement: "top" } },
        [
          _c("template", { slot: "title" }, [
            _vm.localized.ja
              ? _c("div", [
                  _c("p", [_vm._v(_vm._s(_vm.localized.ja.title))]),
                  _c("p", [_vm._v(_vm._s(_vm.localized.ja.content))])
                ])
              : _vm._e(),
            _vm.localized.en
              ? _c("div", [
                  _c("p", [_vm._v(_vm._s(_vm.localized.en.title))]),
                  _c("p", [_vm._v(_vm._s(_vm.localized.en.content))])
                ])
              : _vm._e(),
            _vm.localized.ko
              ? _c("div", [
                  _c("p", [_vm._v(_vm._s(_vm.localized.ko.title))]),
                  _c("p", [_vm._v(_vm._s(_vm.localized.ko.content))])
                ])
              : _vm._e(),
            _vm.localized.zh
              ? _c("div", [
                  _c("p", [_vm._v(_vm._s(_vm.localized.zh.title))]),
                  _c("p", [_vm._v(_vm._s(_vm.localized.zh.content))])
                ])
              : _vm._e()
          ]),
          _c("div", { attrs: { tooltip: "" } }, [
            _c(
              "p",
              [
                _c(
                  "ellipsis",
                  { staticClass: "font-bold", attrs: { length: 100 } },
                  [_vm._v(_vm._s(_vm.info.default_title))]
                )
              ],
              1
            ),
            _c(
              "p",
              [
                _c("ellipsis", { attrs: { length: 100 } }, [
                  _vm._v(_vm._s(_vm.info.default_content))
                ])
              ],
              1
            )
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }