//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import moment from 'moment';

export default Vue.extend({
  computed: {
    info() {
      return this.params.data;
    },
    combindHelpText() {
      const data = moment(this.info.starting_time);
      const { sending_schedule, frequency_type } = this.info;
      if (!sending_schedule) return '';
      return this.buildTextRepeat(this.info.frequency_type, {
        months: sending_schedule?.months?.map(item => this.$t(item)).join(',') ?? '',
        days:
          sending_schedule?.days
            ?.map(item => {
              return item === 'Last' ? this.$t('message.compose.Last') : this.$t(item);
            })
            .join(frequency_type === 'WEEKLY' ? '/' : ',') ?? '',
        time: data.format('HH:mm'),
        repeat: sending_schedule.repeat_every
      });
    }
  },
  methods: {
    buildTextRepeat(type, { months, days, time, repeat }) {
      const mapping = {
        ONCE: {
          0: '',
          multi: ''
        },
        DAILY: {
          1: this.$t('message.compose.Daily message single', { time }),
          multi: this.$t('message.compose.Daily message multi', { repeat, time })
        },
        WEEKLY: {
          1: this.$t('message.compose.Weekly message single', { days, time }),
          multi: this.$t('message.compose.Weekly message multi', { repeat, days, time })
        },
        MONTHLY: {
          12: this.$t('message.compose.Monthly message single', { days, time }),
          multi: this.$t('message.compose.Monthly message multi', { months, days, time })
        }
      };
      return type === 'MONTHLY'
        ? mapping[type][months.split(',').length] || mapping[type].multi
        : mapping[type][repeat] || mapping[type].multi;
    }
  }
});
