var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cell-wrapper-grid-content" }, [
    _vm.combindHelpText
      ? _c(
          "p",
          [
            _c("span", { staticClass: "mr-xs" }, [
              _vm._v(_vm._s(_vm.$t("Repeated Times")) + ":")
            ]),
            _vm.combindHelpText.length > 35
              ? _c("a-tooltip", { attrs: { title: _vm.combindHelpText } }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm._f("truncate")(_vm.combindHelpText, 35)))
                  ])
                ])
              : _c("span", [_vm._v(_vm._s(_vm.combindHelpText))])
          ],
          1
        )
      : _c("p", [_vm._v(_vm._s(_vm.$tc("format.repeat", 0)))]),
    _c("p", [
      _c("span", { staticClass: "mr-xs" }, [
        _vm._v(_vm._s(_vm.$t("Start")) + ":")
      ]),
      _c("span", [
        _vm._v(
          _vm._s(_vm._f("dayjs")(_vm.info.starting_time, "YYYY/MM/DD HH:mm"))
        )
      ])
    ]),
    _vm.info.end_time
      ? _c("p", [
          _c("span", { staticClass: "mr-xs" }, [
            _vm._v(_vm._s(_vm.$t("End")) + ":")
          ]),
          _c("span", [
            _vm._v(_vm._s(_vm._f("dayjs")(_vm.info.end_time, "YYYY/MM/DD")))
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }