//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
export default Vue.extend({
  computed: {
    attachments() {
      return this.params.data?.attachment?.map(item => item.type) || [];
    },
    iconType() {
      return {
        url: 'link',
        pdf: 'file-pdf',
        picture: 'file-image'
      };
    },
    iconColor() {
      return {
        url: '#f50',
        pdf: '#87d068',
        picture: '#108ee9'
      };
    },
    qrcode() {
      return this.params.data.qrcode;
    }
  }
});
