//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
export default Vue.extend({
  computed: {
    info() {
      return this.params.data;
    },
    lang() {
      return this.info.localized_messages.map(l => l.lang).join(', ');
    },
    isRejected() {
      return this.params.data['status'] === 'REJECTED';
    },
    unRead() {
      return this.params.data['adminViewed'] === false;
    },
    localized() {
      return {
        en: this.info.localized_messages.find(item => item.lang === 'en'),
        ko: this.info.localized_messages.find(item => item.lang === 'ko'),
        ja: this.info.localized_messages.find(item => item.lang === 'ja'),
        zh: this.info.localized_messages.find(item => item.lang === 'zh')
      };
    }
  }
});
