var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cell-wrapper-grid-content" }, [
    _vm._v(
      " " + _vm._s(_vm._f("dayjs")(_vm.params.value, "YYYY/MM/DD HH:mm")) + " "
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }