var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isRejected
        ? _c("a-badge", {
            attrs: { status: "error", text: _vm.info.default_title }
          })
        : _c("div", [_vm._v(_vm._s(_vm.info.default_title))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }