var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "page-header",
        { staticClass: "mb-xs", attrs: { title: _vm.$t("Message list") } },
        [
          _c(
            "template",
            { slot: "extra" },
            [
              _c(
                "a-button",
                {
                  attrs: {
                    disabled: _vm.countPushRemain < 1,
                    type: "danger",
                    icon: "plus"
                  },
                  on: { click: _vm.doAdd }
                },
                [_vm._v(_vm._s(_vm.$tc("New Compose")))]
              )
            ],
            1
          )
        ],
        2
      ),
      _c(
        "search-box",
        {
          staticClass: "mb-xs",
          attrs: { queryParam: _vm.queryParam },
          on: {
            changeTitle: _vm.changeTitle,
            changeStatus: _vm.changeStatus,
            handleSearch: _vm.handleSearch,
            handleReset: _vm.handleReset
          }
        },
        [
          _c("template", { slot: "right" }, [
            _c("div", { staticClass: "note-text red-color" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("RemainPush", { count: _vm.countPushRemain })) +
                  " "
              )
            ])
          ])
        ],
        2
      ),
      _c(
        "a-spin",
        { attrs: { spinning: _vm.loading } },
        [
          _c("SGrid", {
            ref: "sGrid",
            attrs: {
              rowData: _vm.rowData,
              enableCheckbox: false,
              columnDefs: _vm.columnDefs,
              pagination: _vm.pagination,
              gridContext: _vm.context,
              rowHeight: 80,
              gridName: "message"
            },
            on: {
              selectionChanged: _vm.onSelectionChanged,
              onPageChange: _vm.onPageChange
            }
          })
        ],
        1
      ),
      _vm.device !== "desktop" && !_vm.loading
        ? _c(
            "a-affix",
            {
              style: { "text-align": "right" },
              attrs: { "offset-bottom": 10 }
            },
            [
              _c("q-btn", {
                staticClass: "q-mr-md",
                attrs: { size: "md", round: "", color: "red-5", icon: "add" },
                on: { click: _vm.doAdd }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }