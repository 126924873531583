import { render, staticRenderFns } from "./AttachmentRenderer.vue?vue&type=template&id=bd11c846&scoped=true&"
import script from "./AttachmentRenderer.vue?vue&type=script&lang=js&"
export * from "./AttachmentRenderer.vue?vue&type=script&lang=js&"
import style0 from "./AttachmentRenderer.vue?vue&type=style&index=0&id=bd11c846&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd11c846",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1580246827/src/univoice-company-console/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bd11c846')) {
      api.createRecord('bd11c846', component.options)
    } else {
      api.reload('bd11c846', component.options)
    }
    module.hot.accept("./AttachmentRenderer.vue?vue&type=template&id=bd11c846&scoped=true&", function () {
      api.rerender('bd11c846', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/message/grid/AttachmentRenderer.vue"
export default component.exports