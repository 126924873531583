var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-tooltip",
        [
          _vm.isRejected
            ? _c("template", { slot: "title" }, [
                _vm._v(" " + _vm._s(_vm.info.rejecting_note) + " ")
              ])
            : _vm._e(),
          _c("div", { staticStyle: { "line-height": "1" } }, [
            _vm._v(_vm._s(_vm.$t(_vm.info.status)))
          ]),
          _vm.isSent
            ? _c("div", { staticStyle: { "text-align": "center" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      "(" +
                        _vm.scheduledActivated +
                        " / " +
                        _vm.scheduleTotal +
                        ")"
                    ) +
                    " "
                )
              ])
            : _vm._e(),
          _vm.isRejected
            ? _c("a-icon", {
                attrs: {
                  theme: "twoTone",
                  "two-tone-color": "#eb2f96",
                  type: "warning"
                }
              })
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }